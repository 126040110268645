.logo {
  position: relative;
}
h3.header-title {
  color: rgb(171, 7, 7);
  position: absolute;
  padding-top: 2.5%;
}
@media (max-width: 450px) {
  .logo {
    position: relative;
  }
  h3.header-title {
    position: absolute;
    padding-top: 9.2%;
  }
}
@media only screen and (max-width: 767px) {
  .header-area.header--transparent {
    padding-left: 10px !important;
    padding-right: 30px;
  }
}
